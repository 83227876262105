<template lang="pug">
#Edit
  .wrapper
    FormProvider

</template>

<script>
import FormProvider from "@/components/FormConfigProvider.vue";
export default {
  name: "Form",
  components: {
    FormProvider,
  },
  data() {
    return {
      activeNotification: false,
    }
  },
  computed: {
    notification() {
      return this.$store.state.notification;
    },
  },
  methods: {
    goHome() {
      if (this.$route.name !== "documents") {
        this.$router.push({ name: "documents" });
      }
    }
  },
}
</script>
